import SiteWrapper from "../../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../../components/mdrender";
import * as md from "../../../components/mdrender.module.sass";
import TitleBlock from "../../../components/title";

import "./event-grouping-and-enrichment@gen.scss";

const GeneratedProductFeaturesEventGroupingAndEnrichment = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          アラートの集約と分類 - PagerDuty正規代理店 - 株式会社Digital Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="アラートのトリアージと機能"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta name="description" content="アラートのトリアージと機能" />

        <meta
          property="og:title"
          content="アラートの集約と分類 - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta property="og:description" content="アラートのトリアージと機能" />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/features/event-grouping-and-enrichment/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/features/event-grouping-and-enrichment/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="アラートの集約と分類"
        overview=""
      />

      <div className="egae-wrapper">
        <div className="center-2 first-section">
          <h2 className="center-title">アラートのトリアージと機能</h2>
          <p className="center-para">
            PagerDutyを使用すると、DevOpsとIT
            Opsのチームは、重要なアプリケーションとインフラストラクチャのすべてのデータを単一のプラットフォームに集約することができます。
            <br />
          </p>
          <p className="center-para">
            アラートをグループ化することで、関連するすべてのコンテキストを単一のビューに統合することができ、チームは重大な問題について簡単に特定、優先順位付けすることができます。
          </p>
        </div>
        {/* details */}
        <div className="center-1">
          <h2 className="center-title">
            イベントの正規化機能、機能の詳細をご覧ください
          </h2>
        </div>
        <div className="three-col-without-cta">
          <div className="card">
            <div className="title">
              <p>アラートテーブルCEFフィールド</p>
            </div>
            <p>
              フィルタリングし、正規化されたフィールドを使用してアラートを検索します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>インシデントマージAPI</p>
            </div>
            <p>関連するアラートをAPI経由で自動的にマージします。</p>
          </div>
          <div className="card">
            <div className="title">
              <p>アラートトリアージ</p>
            </div>
            <p>
              アラートを1つのインシデントに統合することで、ノイズを大幅に削減し応答時間を短縮します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>ルールエンジン</p>
            </div>
            <p>
              大量のデータをプログラムで管理し、アラートやインシデントの動作をインテリジェントに調整します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>抑制</p>
            </div>
            <p>&lt;問題の指標を把握しながら、イベント通知を受け取ります。 </p>
          </div>
          <div className="card">
            <div className="title">
              <p>セルフサービス統合とイベント集中化</p>
            </div>
            <p>
              PagerDutyはあらゆる監視、デプロイ、チケットツールと簡単に統合でき、すべての重要なデータを集中管理できます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>正規化</p>
            </div>
            <p>
              統合された監視ツールとカスタムツールのアラートデータは、共通の形式に正規化することで、負荷を軽減します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>充実化されたインシデント</p>
            </div>
            <p>
              インシデントの詳細には、関連するすべてのアラートコンテキストを統合し、グラフ、イメージ、ランブックリンク、メタデータ、電話会議番号を直接組み込むことができます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>Events API v2</p>
            </div>
            <p>
              Events
              APIを使用すると、すべてのイベントデータを正規化し、分類するのが簡単になります。
            </p>
          </div>
        </div>
        <div className="conclusion">
          PagerDutyのインシデント解決プラットフォームは、ノイズを削減し、
          <br />
          インシデントをより迅速に解決するのに役立ちます。
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProductFeaturesEventGroupingAndEnrichment;
